@font-face {
  font-family: "Bookerly";
  font-style: normal;
  font-weight: 200;
  src: url("bookerly-light.woff2") format("woff2"), url("bookerly-light.woff") format("woff"), url("bookerly-light.ttf") format("truetype");
}

@font-face {
  font-family: "Bookerly";
  font-style: normal;
  font-weight: 400;
  src: url("bookerly-regular.woff2") format("woff2"), url("bookerly-regular.woff") format("woff"), url("bookerly-regular.ttf") format("truetype");
}